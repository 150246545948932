import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [0];

export const dictionary = {
		"/": [4],
		"/(protected)/account": [~8,[3]],
		"/(auth)/auth/code": [~5,[2]],
		"/(protected)/dashboard": [~9,[3]],
		"/(auth)/error": [6,[2]],
		"/(protected)/exploits": [10,[3]],
		"/(protected)/logout": [~11,[3]],
		"/(protected)/products": [12,[3]],
		"/(protected)/products/[productSlug]": [~13,[3]],
		"/(protected)/sources/[sourceSlug]": [14,[3]],
		"/(protected)/sources/[sourceSlug]/[vulnId]": [~15,[3]],
		"/(protected)/tags/[tagId]": [~16,[3]],
		"/(protected)/team": [~17,[3]],
		"/(protected)/team/create": [18,[3]],
		"/(protected)/team/integrations": [~19,[3]],
		"/(protected)/team/settings": [~20,[3]],
		"/(protected)/vendors/[vendorSlug]": [~21,[3]],
		"/(auth)/verify-request": [~7,[2]],
		"/(protected)/vulnerabilities": [~22,[3]],
		"/(protected)/vulnerabilities/[vulnId]": [~23,[3]],
		"/(protected)/vulns/[vulnId]": [~24,[3]],
		"/(protected)/weaknesses": [25,[3]],
		"/(protected)/weaknesses/[slug]": [26,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';